import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './pages/contact';
import AboutUs from './pages/AboutUs';
import PipelineTable from './components/PipelineTable';
import UnderConstruction from './components/UnderConstruction';
import PublicationsSection from './components/Publications';
import TermsConditions from './components/TermsCondition';
import NewsList from './pages/NewsList';
import NewsDetails from './pages/NewsDetailspage';
import PrivacyPolicy from './components/PrivacyPolice';
import Careers from './components/Careers';
import BlogsList from './pages/BlogsList';

 
function App() {

  useEffect(() => {
    // Initialize Google Analytics
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-C13YLCYQV2`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-C13YLCYQV2');
  }, []);


  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/news-list" element={<NewsList/>}/>
          <Route path='/newsdetails' element={<NewsDetails/>}/>
          <Route path="/teams"element={<AboutUs/>} />
          <Route path="/pipeline"element={<PipelineTable/>} />
          <Route path="/publications" element={<PublicationsSection/>}/>
          <Route path='/terms' element={<TermsConditions/>}/>
          <Route path='/privacy' element={<PrivacyPolicy/>}/>
          <Route path="/case-study"element={<UnderConstruction/>} />
          <Route path='/blogs' element={<UnderConstruction/>}/>
          <Route path='/press-relase' element={<UnderConstruction/>}/>
          <Route path="/press-release"element={<UnderConstruction/>} />
          {/* <Route path='/careers' element={<UnderConstruction/>}/> */}
        </Routes>
       
      </div>
    </Router>
  );
}
 
export default App;
 
 
 