/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { FaLinkedin, FaInstagram, FaYoutube, FaTelegramPlane, FaTwitter, FaInfoCircle, FaUsers, FaPhoneAlt } from "react-icons/fa";
import { PiGreaterThan } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import logo from '../images/Centella-Logo.png';
import emailjs from 'emailjs-com';

const Footer = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [email, setEmail] = useState("");  // State for email input
    const navigate = useNavigate();

    const handleCardClick = () => {
        setIsHovered(!isHovered);
    };

    const handleInputClick = () => {
        navigate('/contact');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.send('service_isrdry5', 'template_tsszq6m', {
            email: email,  // Send the email entered by the user
        }, '4h1JqCyCQAUiBn3oR')
        .then((result) => {
            console.log('Email sent successfully:', result.text);
           
        }, (error) => {
            console.error('Error sending email:', error.text);
          
        });
    
        setEmail('');  // Clear the email input after submission
    };

    return (
        <footer className="bg-gradient-to-r from-[#332E40] via-[#413E4E] to-[#054267] text-white py-24 w-[100%] h-[100%]">
            <div className="max-w-7xl mx-auto px-4 md:px-8">
                {/* Main Content Wrapper */}
                <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 lg:space-x-12 font-sofia">
                    {/* Left Column: Contact Us Form */}
                    <div
                        onClick={handleCardClick}
                        className={`w-full md:w-[50%] lg:w-[30%] h-auto bg-[#313131] border border-[#6FC5F0] bg-opacity-50 p-8 rounded-lg transition-transform duration-300 ease-in-out ${
                            isHovered ? 'scale-105' : ''
                        } hover:scale-105 cursor-pointer`}
                    >
                        <div className="text-blue-300 uppercase text-xl tracking-[1px] font-semibold mb-4 flex items-center">
                            <FaPhoneAlt className="mr-1" /> Contact Us
                        </div>
                        <div className="text-2xl lg:text-3xl font-semibold mb-4">
                            <span className="text-gray-400">Seeking personalized support?</span> Request a call from our team
                        </div>
                        <form className="space-y-4">
                            <div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Your Name"
                                    className="w-full p-3 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 border border-white"
                                    onClick={handleInputClick}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="Phone Number"
                                    className="w-full p-3 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 border border-white"
                                    onClick={handleInputClick}
                                />
                            </div>
                            <button type="submit" className="w-full sm:w-[60%] py-3 bg-[#6FC5F0] rounded-[50px] font-semibold text-black">
                                Send a request
                            </button>
                        </form>
                        <Link className="text-gray-400 inline-block" onClick={() => navigate('/privacy')} to="/privacy">
                            Privacy Policy
                        </Link>
                        <Link className="text-gray-400 mt-4 ml-6 inline-block"  to="#">
                            Terms & Conditions
                        </Link>
                    </div>

                    {/* Right Column: Info, About Us, Contact Us, Subscription */}
                    <div className="flex-1 flex flex-col space-y-8">
                        {/* Info, About Us, Contact Us in One Row */}
                        <div className="flex flex-col lg:flex-row justify-between lg:space-x-12 space-y-8 lg:space-y-0">
                            <div className="flex-1">
                                <div className="uppercase text-[#6FC5F0] text-xl mb-4 flex items-center">
                                    <FaInfoCircle className="mr-2" /> Info
                                </div>
                                <ul className="space-y-2 text-xl">
                                    <li><a href="#"  className="hover:text-blue-400">Platform</a></li>
                                    <li><a href="#"  className="hover:text-blue-400">Pipeline</a></li>
                                    <li><a href="#"  className="hover:text-blue-400">Science</a></li>
                                    <li><a href="#"  className="hover:text-blue-400">News & Updates</a></li>
                                    
                                     <li><a  className="hover:text-blue-400 ">About Us</a></li>
                                </ul>
                            </div>

                            <div className="flex-1 text-xl">
                                <div className="uppercase text-[#6FC5F0]  mb-4 flex items-center">
                                    <FaUsers className="mr-2" /> About Us
                                </div>
                                <ul className="space-y-2 text-xl">
                                    <li><a href="#" className="hover:text-blue-400">Why Centella</a></li>
                                    <Link to='/teams'> <li><a href="#"  className="hover:text-blue-400">Team</a></li></Link>
                                </ul>
                            </div>

                            <div className="flex-1">
                                <div className="uppercase text-[#6FC5F0] text-xl mb-4 flex items-center">
                                    <FaPhoneAlt className="mr-2" /> Contact Us
                                </div>
                                {/* <p className="text-white hover:text-blue-400 text-xl">+91 905 995 5894</p> */}
                                <p className="text-white py-2 hover:text-blue-400 text-xl">connect@centella.co.in</p>
                                {/* <p className="text-white hover:text-blue-400 text-xl">Hyderabad, India.</p> */}
                            </div>
                            <div className="flex-1">
                                <img src={logo} className="ml-4" height={70} width={70} alt="Centella Logo" />
                            </div>
                        </div>

                        {/* Subscription Section */}
                        <div className="mt-12">
                            <div className="uppercase text-[#6FC5F0] text-xl tracking-[1px] mb-4">Subscription</div>
                            <form onSubmit={sendEmail} className="relative w-[95%]"> {/* Wrap with form */}
                                <input
                                    type="email"
                                    placeholder="E-mail"
                                    className="w-full p-3 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 border border-white pr-12"
                                    value={email}  // Bind input value to state
                                    onChange={handleEmailChange}  // Update state on input change
                                />
                                <button type="submit" className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <div className="border-l border-gray-500 h-6 mr-4"></div> {/* Vertical Line */}
                                    <span className="text-[#6FC5F0]"><PiGreaterThan /></span> {/* Right Arrow */}
                                </button>
                            </form>

                            {/* Social Icons */}
                            <div className="flex space-x-4 mt-6">
                                <a href="https://www.linkedin.com/showcase/centella-ai-therapeutics/" className="text-gray-400 hover:text-blue-400"><FaLinkedin /></a>
                                <a href="https://x.com/CentellaAi" className="text-gray-400 hover:text-blue-400"><FaTwitter height={30} width={30} />  </a>
                                <a  className="text-gray-400 "><FaInstagram />  </a>
                                <a  className="text-gray-400 "><FaYoutube /></a>
                                <a  className="text-gray-400 "><FaTelegramPlane /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="text-center text-gray-500 mt-12">
                <p>© 2024 Centella. All rights reserved.</p>
                
            </div>
           
        </footer>
    );
};

export default Footer;
